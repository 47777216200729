<script setup lang="ts">
import { useKeenSlider } from 'keen-slider/vue';
import { CoinSettingAttrs } from '~~/utils/models/CoinSetting';

defineProps<{ coinSettings: CoinSettingAttrs[] }>();

const activeIndex = ref(0);
const [keenSlider, keen] = useKeenSlider({
  slides: { perView: 'auto', spacing: 16 },
  breakpoints: {
    '(min-width: 1024px)': {
      slides: { perView: 'auto', spacing: 16 },
    },
  },
  slideChanged: (keen) => {
    activeIndex.value = keen.track.details.rel;
  },
});
</script>

<template>
  <section class="overflow-hidden cursor-grab">
    <div class="flex px-24">
      <div class="grow h-[265px] relative">
        <div
          class="absolute inset-y-0 left-0 -right-[calc((100vw_-_1200px)_/_3)]"
        >
          <div class="mr-[calc((100vw_-_1200px)_/_3)] h-full">
            <div class="keen-slider !overflow-visible h-full" ref="keenSlider">
              <div
                class="keen-slider__slide shrink-0 basis-[198px] h-full"
                v-for="coinSetting of coinSettings.filter(
                  (x) => x.slug != 'comment-article'
                )"
                :key="coinSetting.id"
              >
                <LazyModalCoinItem :coinSetting="coinSetting" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
